import { Button } from "components/button";
import { Cloudinary, CloudinaryUrlHandler } from "../../../cloudinary";
import { DetailProcessHeaderProps } from "constants/types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useFullscreen, useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import Link from "components/link";
import React, { FC, useEffect, useState, forwardRef } from "react";

export const DetailedProcessHeader: FC<DetailProcessHeaderProps> = forwardRef<
  HTMLDivElement,
  DetailProcessHeaderProps
>(({ media, links, active, id, activeHover, setActiveHover }, ref) => {
  let touchDevice = false;
  if (typeof document !== "undefined") {
    touchDevice = "ontouchstart" in document.documentElement;
  }

  const [backToIndustryLabel] = useLabels(["ui-942", "back to overview"]);
  const [fullscreen, toggleFullscreen] = useFullscreen();
  const slugPath = useSlugPath();
  const parentPath = slugPath.split("/").slice(0, -1).join("/");
  const pageLevel = slugPath.split("/").filter(Boolean).length;

  const [heroLoaded, setHeroLoaded] = useState<boolean>(false);
  const [glow, setGlow] = useState<boolean>(false);

  useEffect(() => {
    if (heroLoaded) {
      setGlow(true);
      setTimeout(() => {
        setGlow(false);
      }, 6000);
    }
  }, [heroLoaded]);

  useEffect(() => {
    // everytime the id changes, reset heroLoaded to false
    setHeroLoaded(false);
  }, [id]);

  const { country } = useLocale();

  useEffect(() => {
    if (fullscreen) {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  }, [fullscreen]);

  return (
    <div
      ref={ref}
      id={id}
      key={id}
      className="detailed-process-header row d-block"
      style={{
        backgroundSize: "cover",
        backgroundImage: `url(${CloudinaryUrlHandler({
          media: {
            digital_asset_id: "im-background/grey-triangle-4-3",
          },
          isChina: country === "cn",
        })}`,
      }}
    >
      <div className="detailed-process-header__content">
        <div className="detailed-process-links-wrapper">
          <div className="detailed-process-links">
            {links.map((link) => {
              if (!link) return null;

              if (link.url) {
                return (
                  link?.coords?.length > 0 &&
                  link?.coords?.map((coord, index) => (
                    <Link
                      href={`${link.url}#${link.id}`}
                      prefetch={false}
                      key={`dp-link-${index}`}
                      className={classNames(
                        "detailed-process-header-link",
                        glow && "m--glow",
                        touchDevice && " m--no-hover",
                      )}
                      {...(!touchDevice && {
                        onMouseEnter: link.onHover,
                        onMouseLeave: () => setActiveHover(active),
                      })}
                      style={{
                        width: `${coord.w * 100}%`,
                        height: `${coord.h * 100}%`,
                        top: `${coord.y * 100}%`,
                        left: `${coord.x * 100}%`,
                        borderRadius: coord.circle ? "100%" : 0,
                      }}
                    >
                      {link.label}
                    </Link>
                  ))
                );
              }

              if (link.onClick) {
                return (
                  link?.coords?.length > 0 &&
                  link?.coords?.map((coord, index) => (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 0, hide: 0 }}
                      overlay={
                        <Popover id={`coords-${index}`} className="popover">
                          {link.label}
                        </Popover>
                      }
                      key={index}
                    >
                      <a
                        key={`coords-${index}`}
                        className={classNames(
                          "detailed-process-header-link",
                          glow && "m--glow",
                          touchDevice && " m--no-hover",
                        )}
                        onClick={link.onClick}
                        {...(!touchDevice
                          ? {
                              onMouseEnter: link.onHover,
                              onMouseLeave: () => setActiveHover(active),
                            }
                          : {})}
                        style={{
                          width: `${coord.w * 100}%`,
                          height: `${coord.h * 100}%`,
                          top: `${coord.y * 100}%`,
                          left: `${coord.x * 100}%`,
                          borderRadius: coord.circle ? "100%" : 0,
                        }}
                      />
                    </OverlayTrigger>
                  ))
                );
              }

              return null;
            })}
          </div>
        </div>
        <div className="detailed-process-header-overlay">
          {links.map(
            (link, i) =>
              link.keyvisual && (
                <Cloudinary
                  key={i}
                  media={link.keyvisual}
                  ar="ar2410"
                  className={classNames(
                    "dp-overlay",
                    ((active !== null && i === active + 1) ||
                      (activeHover !== null && i === activeHover + 1)) &&
                      "dp-overlay-active",
                  )}
                  priority={true}
                />
              ),
          )}
          {media && (
            <Cloudinary
              media={media}
              onLoad={() => {
                setHeroLoaded(true);
              }}
              className="dp-overlay-base"
              ar={"ar2410"}
              priority={true}
            />
          )}
        </div>
      </div>

      <div className="detailed-process-header__buttons">
        {pageLevel === 4 && (
          <Button
            label={backToIndustryLabel.title}
            icon="caret-left"
            url={parentPath}
          />
        )}
        <Button
          variant="white"
          label=""
          icon={fullscreen ? "windowed" : "fullscreen"}
          onlyIcon
          onClick={toggleFullscreen}
          className="mr-0"
        />
      </div>
    </div>
  );
});

DetailedProcessHeader.displayName = "DetailedProcessHeader";
